import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

type UserRole = 'user' | 'admin';
type UserStatus = 'ACTIVE' | 'INACTIVE' | 'PENDING';

interface AuthContextType {
  user: string | null;
  role: UserRole | null;
  status: UserStatus | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  secureFetch: (input: RequestInfo, init?: RequestInit, skipSessionCheck?: boolean) => Promise<Response>;
  checkSession: () => Promise<void>;
  responseMessage: string | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<string | null>(null);
  const [role, setRole] = useState<UserRole | null>(null);
  const [status, setStatus] = useState<UserStatus | null>(null);
  const [responseMessage, setResponseMessage] = useState<string | null>(null);

  const navigate = useNavigate();

  const checkSession = async () => {
    if (status !== 'ACTIVE') {
      setStatus('PENDING');
      try {
        const response = await fetch(`/auth`, {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setUser(data.username);
          setRole(data.user_role);
          setStatus('ACTIVE');
        } else {
          setUser(null);
          setRole(null);
          setStatus('INACTIVE');
          navigate('/login');
        }
      } catch {
        setUser(null);
        setRole(null);
        setStatus('INACTIVE');
        navigate('/login');
      }
    }
  };

  useEffect(() => {
    checkSession();
  }, [navigate]);

  const login = async (username: string, password: string) => {
    setResponseMessage(null);
    try {
      const response = await fetch(`/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
        credentials: 'include',
      });

      const data = await response.json();

      if (response.ok) {
        setUser(data.username);
        setRole(data.role);
        setStatus('ACTIVE');
        navigate('/');
      } else {
        setUser(null);
        setRole(null);
        setStatus('INACTIVE');
        setResponseMessage(data.message);
      }
    } catch (error) {
      setUser(null);
      setRole(null);
      setStatus('INACTIVE');
      setResponseMessage('Service unavailable, try again later.');
    }
  };

  const logout = async () => {
    setResponseMessage(null);
    try {
      const response = await fetch(`/logout`, {
        method: 'POST',
        credentials: 'include',
      });

      const data = await response.json();

      if (response.ok) {
        setUser(null);
        setRole(null);
        setStatus('INACTIVE');
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * secureFetch - A function to perform HTTP requests with session validation and credentials included.
   * The idea is to use this function to make all the requests in this app.
   * This function ensures that a user's session is valid before making the request and includes credentials in all requests.
   * It allows skipping session validation for specific requests using the `skipSessionCheck` option.
   *
   * @param {RequestInfo} input - The URL or Request object for the HTTP request.
   * @param {RequestInit} [init] - Optional configuration object for the fetch request (e.g., method, headers, body).
   * @param {boolean} [skipSessionCheck] - If true, skips the session validation check.
   * @returns {Promise<Response>} - The response from the fetch request.
   * @throws {Error} - Throws an error if the session validation fails or the fetch request returns a non-OK status.
   *
   */

  const secureFetch = async (input: RequestInfo, init?: RequestInit, skipSessionCheck?: boolean) => {
    try {
      if (!skipSessionCheck) {
        await checkSession();
      }
      const response = await fetch(input, { ...init, credentials: 'include' });

      if (!response.ok && response.status !== 404) {
        // Trigger logout only for authentication errors (401 and 403)
        if (response.status === 401 || response.status === 403) {
          await logout(); // Call the logout function on authentication errors
        } else if (response.status !== 409 && response.status !== 400) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      return response;
    } catch (error) {
      console.error('Error during fetch:', error);
      // Ensure logout is only called for errors other than 409 and 400
      if (!(error instanceof Response && (error.status === 409 || error.status === 400))) {
        await logout(); // Call logout for other types of errors
      }
      return new Response(null, { status: 500, statusText: 'Internal Server Error' });
    }
  };

  return (
    <AuthContext.Provider value={{ user, role, status, login, logout, secureFetch, checkSession, responseMessage }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
